import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { TextModel } from '../core/models/text_model';
import { DjangoService } from '../core/services/django_service.services';
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  name: string = '';
  confUrl: string = 'http://localhost:8000/';
  // confUrl: string = 'https://webhook.site/ac8bf1c6-08a2-4635-b38b-993f4010297a';
  soz: any;
  metn: any;
  metn_result: any = '';
  soz_analizi: any;
  soz_result: any = '';
  luget: any;
  submitted:boolean= false;

  nitq = [
    {code: 'isim', name: "İsim"},
    {code: 'sifet', name: "Sifət"},
    {code: 'feil', name: "Feil"},
    {code: 'say', name: "Say"},
    {code: 'zerf', name: "Zərf"},
    {code: 'evezlik', name: "Əvəzlik"},
  ];
  selectedNitq:any;
  nitq_result: any;

  constructor(private router: Router, private route: ActivatedRoute, private http: DjangoService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.name = this.route.snapshot.params.name;
        console.log(this.route.snapshot);
      }
    });

  }

  ngOnInit(): void {
    this.submitted = false;
    if(this.metn_result == '') {
      this.submitted = false;
    }
  }

  metnGonder(result: any): void {
    const tm = new TextModel(result, '');
    this.submitted = true;
    this.http.sendText(tm, this.name)
      .subscribe((result) => {
        console.log(result);
        this.metn_result = result;
        this.metn_result = this.metn_result.data;
      },
        (error) => {
          console.log(error);
        });
  }

  sozGonder(result: any): void {
    const sm = new TextModel(result, '');
    this.submitted = true;
    this.http.sendText(sm, this.name)
      .subscribe((result) => {
        console.log(result);
        this.soz_result = result;
        this.soz_result = this.soz_result.data;
      },
        (error) => {
          console.log(error);
        });
  }

  nitqGonder(result: any, nitq:string): void {
    console.log(result, nitq);

    const tm = new TextModel(result, nitq);
    this.submitted = true;
    this.http.sendText(tm, this.name)
      .subscribe((result) => {
        console.log(result);
        this.nitq_result = result;
        this.nitq_result = this.nitq_result.data;
      },
        (error) => {
          console.log(error);
        });
  }

}
