import { LanguageComponent } from './components/language/language.component';
import { TeamComponent } from './components/team/team.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'haqqinda', component: AboutusComponent},
  {path: 'elaqe', component: ContactComponent},
  {path: 'komanda', component: TeamComponent},
  {path: 'analiz/:name', component: LanguageComponent},
  {path: '**', component: NotfoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
