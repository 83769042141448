export class TextModel {

    public metn: String;
    public nitq: String;

    constructor(metn: String, nitq: String) {
        this.metn = metn;
        this.nitq = nitq;
    }
}
